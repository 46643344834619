import { createRouter, createWebHashHistory } from "vue-router";
import { BasicLayout, BlankLayout } from "@/layouts";

export const mainRouter = [
  {
    //后台系统路由
    path: "/backManage",
    name: "BackManage",
    code:'HTGLMK',
    component: BasicLayout,
    children: [
      {
        path: "/backManage/businessManage",
        meta: {
          title: "企业管理",
        },
        children: [
          {
            path: "/backManage/businessManage/tenantManage",
            name: "TenantManage",
            component: () =>
              import(
                "@/views/backManage/businessManage/tenantManage/index.vue"
              ),
            meta: {
              title: "租户管理",
            },
          },
          {
            path: "/backManage/businessManage/businessInfo",
            name: "BusinessInfo",
            component: () =>
              import(
                "@/views/backManage/businessManage/businessInfo/index.vue"
              ),
            meta: {
              title: "企业信息",
            },
          },
        ],
      },
      {
        path: "/backManage/rightManage",
        redirect: "/backManage/rightManage/applicationMenu",
        meta: {
          title: "权限管理",
        },
        children: [
          {
            path: "/backManage/rightManage/applicationMenu",
            name: "ApplicationMenu",
            component: () =>
              import(
                "@/views/backManage/rightManage/applicationMenu/index.vue"
              ),
            meta: {
              title: "应用菜单",
            },
          },
          {
            path: "/backManage/rightManage/roleManage",
            name: "RoleManage",
            component: () =>
              import("@/views/backManage/rightManage/roleManage/index.vue"),
            meta: {
              title: "角色管理",
            },
          },
          {
            path: "/backManage/rightManage/userManage",
            name: "UserManage",
            component: () =>
              import("@/views/backManage/rightManage/userManage/index.vue"),
            meta: {
              title: "用户管理",
            },
          },
        ],
      },
      {
        path: "/backManage/dictionaryManage",
        name: "DictionaryManage",
        component: () =>
          import("@/views/backManage/dictionaryManage/index.vue"),
        meta: {
          title: "字典管理",
        },
      },
      {
        path: "/backManage/timedTasks",
        name: "TimedTasks",
        component: () =>
          import("@/views/backManage/timedTasks/index.vue"),
        meta: {
          title: "定时任务管理",
        },
      },
      {
        path: "/backManage/noticeMessage",
        name: "NoticeMessage",
        component: () =>
          import("@/views/backManage/noticeMessage/index.vue"),
        meta: {
          title: "通知消息",
        },
      },
    ],
  },
  {
    //物联管理平台
    path: "/IoTManage",
    name: "IoTManage",
    component: BasicLayout,
    code:'WLGLPTMK',
    redirect: "/IoTManage/IoTManage",
    children: [
      {
        path: "/IoTManage/IoTManage",
        redirect: "/IoTManage/IoTManage/ProductLibrary",
        meta: {
          title: "产品中心",
        },
        children: [
          {
            path: "/IoTManage/productCenter/productLibrary",
            name: "ProductLibrary",
            component: () =>
              import(
                "@/views/IoTManage/productCenter/productLibrary/index.vue"
              ),
            meta: {
              title: "产品库",
            },
          },
          {
            path: "/IoTManage/productCenter/categoryManage",
            name: "CategoryManage",
            component: () =>
              import(
                "@/views/IoTManage/productCenter/categoryManage/index.vue"
              ),
            meta: {
              title: "品类管理",
            },
          },
          {
            path: "/IoTManage/productCenter/functionManage",
            name: "FunctionManage",
            component: () =>
              import(
                "@/views/IoTManage/productCenter/functionManage/index.vue"
              ),
            meta: {
              title: "功能管理",
            },
          },
          {
            path: "/IoTManage/productCenter/productDetail/:id/:f?",
            name: "ProductDetail",
            component: () =>
              import("@/views/IoTManage/productCenter/productDetail/index.vue"),
            meta: {
              //noAuth: true,
              title: "产品详情",
              noTab: true,
              activeMenu: [
                '/IoTManage/product/list',
                '/IoTManage/productCenter/productLibrary'
              ]
            },
          },
        ],
      },
      {
        path: "/IoTManage/project/list",
        name: "IoTManageProjectManage",
        component: () =>
          import("@/views/IoTManage/projectManage/ProjectList.vue"),
        meta: {
          title: "项目管理",
        },
      },
      {
        path: "/IoTManage/project/detail/:projectId",
        name: "IoTManageProjectDetail",
        component: () =>
          import("@/views/IoTManage/projectManage/ProjectDetail.vue"),
        meta: {
          //noAuth: true,
          title: "项目详情",
          noTab: true,
          activeMenu: ['/IoTManage/project/list']
        },
      },
      {
        path: "/IoTManage/product/list",
        name: "ProductManage",
        component: () =>
          import("@/views/IoTManage/productManage/ProductList.vue"),
        meta: {
          title: "产品管理",
          noTab: true,
        },
      },
      {
        path: "/IoTManage/gateway/list",
        name: "GateWayManage",
        component: () =>
          import("@/views/IoTManage/gateway/index.vue"),
        meta: {
          title: "网关管理",
          noTab: true,
        },
      },
      {
        path: "/IoTManage/gateway/detail/:bizId",
        name: "GateWayManageDetail",
        component: () =>
          import("@/views/IoTManage/gateway/detail/index.vue"),
        meta: {
          //noAuth: true,
          title: "网关详情",
          noTab: true,
          activeMenu: ['/IoTManage/gateway/list']
        },
      },
      {
        path: "/IoTManage/equipmentManage",
        redirect: "/IoTManage/equipmentManage/xx",
        meta: {
          title: "设备管理",
        },
        children: [
          {
            path: "/IoTManage/equipmentManage/list",
            name: "IoTManageEquipmentManageList",
            component: () =>
              import("@/views/IoTManage/equipmentManage/list.vue"),
            meta: {
              title: "设备列表",
            },
          },
          {
            path: "/IoTManage/device/detail/:deviceId",
            name: "IoTManageDeviceDetail",
            component: () =>
              import("@/views/IoTManage/deviceManage/DeviceDetail.vue"),
            meta: {
              //noAuth: true,
              title: "设备详情",
              noTab: true,
              activeMenu: [
                '/IoTManage/equipmentManage/list',
                '/centerMonitor/equipmentMonitor/list',
                '/centerMonitor/projectManage'
              ]
            },
          },
        ]
      },
      {
        path: "/IoTManage/ruleManage",
        name: "RuleManage",
        component: () =>
          import("@/views/IoTManage/ruleManage/list.vue"),
        meta: {
          title: "规则管理",
        },
      },
      {
        path: "/IoTManage/ruleDetail/:id",
        name: "RuleDetail",
        component: () =>
          import("@/views/IoTManage/ruleManage/detail.vue"),
        meta: {
          title: "规则详情",
          //noAuth: true,
          noTab: true,
          activeMenu: ['/IoTManage/ruleManage']
        },
      },
      {
        path: "/IoTManage/alarmPush",
        name: "AlarmPush",
        component: () =>
          import("@/views/IoTManage/alarmPush/list.vue"),
        meta: {
          title: "告警推送",
        },
      },
      {
        path: "/IoTManage/alarmPushDetail/:id",
        name: "AlarmPushDetail",
        component: () =>
          import("@/views/IoTManage/alarmPush/detail.vue"),
        meta: {
          title: "告警推送详情",
          //noAuth: true,
          noTab: true,
          activeMenu: ['/IoTManage/alarmPush']
        },
      },

    ],
  },
  {
    //综合监控系统
    path: "/centerMonitor",
    name: "CenterMonitor",
    code:'ZHJKGLXT',
    component: BasicLayout,
    redirect: "/centerMonitor/projectManage",
    children: [
      {
        path: "/centerMonitor/projectManage",
        name: "CenterMonitorProjectManage",
        component: () => import("@/views/centerMonitor/projectManage/list.vue"),
        meta: {
          title: "项目信息",
        },
      },
      {
        path: "/centerMonitor/project/detail/:projectId",
        name: "CenterMonitorProjectDetail",
        component: () =>
          import("@/views/centerMonitor/projectManage/detail.vue"),
        meta: {
          // noAuth: true,
          activeMenu: ['/centerMonitor/projectManage'],
          title: "项目详情",
          noTab: true,
        },
      },
      {
        path: "/centerMonitor/projectBoard",
        name: "ProjectBoard",
        component: () => import("@/views/centerMonitor/projectBoard/index.vue"),
        meta: {
          title: "能耗管理看板",
        },
      },
      {
        path: "/centerMonitor/projectMonitor",
        name: "ProjectMoitor",
        component: () =>
          import("@/views/centerMonitor/projectMonitor/index.vue"),
        meta: {
          title: "智控策略",
        },
      },
      {
        path: "/centerMonitor/equipmentMonitor",
        redirect: "/centerMonitor/equipmentMonitor/list",
        meta: {
          title: "设备监测",
        },
        children: [
          {
            path: "/centerMonitor/equipmentMonitor/list",
            name: "equipmentMonitor",
            meta: {
              title: "设备监测",
              activeMenu:['/centerMonitor/equipmentMonitor']
            },
            component: () =>
              import("@/views/centerMonitor/equipmentMonitor/list.vue"),
          },
        ],
      },
      {
        path: "/centerMonitor/statistics",
        redirect: "/centerMonitor/statistics/projectReport",
        meta: {
          title: "统计报表",
        },
        children: [
          {
            path: "/centerMonitor/statistics/projectReport",
            name: "ProjectReport",
            component: () =>
              import(
                "@/views/centerMonitor/statistics/projectReport/index.vue"
              ),
            meta: {
              title: "项目报表",
            },
          },
          {
            path: "/centerMonitor/statistics/equipmentReport",
            name: "EquipmentReport",
            component: () =>
              import(
                "@/views/centerMonitor/statistics/equipmentReport/index.vue"
              ),
            meta: {
              title: "设备报表",
            },
          },
        ],
      },
      {
        path: "/centerMonitor/historyAnalysis",
        name: "HistoryAnalysis",
        component: () =>
          import("@/views/centerMonitor/historyAnalysis/index.vue"),
        meta: {
          title: "历史分析",
        },
      },
      {
        path: "/centerMonitor/myMessage",
        name: "CmMyMessage",
        component: () =>
          import("@/views/centerMonitor/myMessage/index.vue"),
        meta: {
          noAuth: true,
          title: "我的消息",
        },
      },
      {
        path: "/centerMonitor/viewConfig",
        name: "ViewConfig",
        component: () => import("@/views/centerMonitor/viewConfig/index.vue"),
        meta: {
          title: "视图配置",
        },
      },
      {
        path: "/centerMonitor/equipmentAlarm",
        name: "EquipmentAlarm",
        component: () => import("@/views/centerMonitor/equipmentAlarm/index.vue"),
        meta: {
          title: "设备告警",
        },
      },
      {
        path: "/centerMonitor/alarmHistory",
        name: "AlarmHistory",
        component: () => import("@/views/centerMonitor/alarmHistory/index.vue"),
        meta: {
          title: "历史事件",
        },
      },
      {
        path: "/centerMonitor/alarmManage",
        name: "AlarmManage",
        component: () => import("@/views/centerMonitor/alarmManage/index.vue"),
        meta: {
          title: "实时告警"
        },
      },
      {
        path: "/centerMonitor/customize/:pathMatch(.*)*",
        component: () => import("@/views/centerMonitor/customize/index.vue"),
        meta: {
          title: "定制页面",
        },
      },
      // {
      //   path: "/centerMonitor/largeScreen",
      //   name: "LargeScreen",
      //   component: () => import("@/views/centerMonitor/largeScreen/index.vue"),
      //   meta: {
      //     title: "大屏展示",
      //   },
      // },
      {
        path: "/centerMonitor/customize/:pathMatch(.*)*",
        component: () => import("@/views/centerMonitor/customize/index.vue"),
        meta: {
          title: "定制页面",
        },
      },
      // {
      //   path: "/centerMonitor/largeScreen",
      //   name: "LargeScreen",
      //   component: () => import("@/views/centerMonitor/largeScreen/index.vue"),
      //   meta: {
      //     title: "大屏展示",
      //   },
      // },
      {
        path: "/demo",
        name: "tableDemo",
        component: () => import("@/views/test/other.vue"),
        //component: () => import("@/views/test/productDetail/index.vue"),
        meta: {
          noAuth: true,
          title: "table-demo",
        },
      },
      {
        path: "/home2",
        name: "Home2",
        component: BlankLayout,
        children: [
          {
            path: "/home2/home21",
            name: "Home21",
            component: () => import("@/views/test/Home21.vue"),
            meta: {
              title: "菜单21",
            },
          },
          {
            path: "/home2/home22",
            redirect: "/home2/home22/list",
            meta: {
              title: "菜单22",
            },
            children: [
              {
                path: "/home2/home22/list",
                name: "Home22list",
                component: () => import("@/views/test/Home22.vue"),
                meta: {
                  title: "菜单22list",
                },
              },
              {
                path: "/home2/home22/edit",
                name: "Home22edit",
                component: () => import("@/views/test/Home22-edit.vue"),
                meta: {
                  title: "菜单22edit",
                },
              },
            ],
          },
          {
            path: "/home2/home23",
            name: "Home23",
            component: () => import("@/views/test/Home23.vue"),
            meta: {
              title: "菜单23",
            },
          },
        ],
      },
      {
        path: "/home3",
        name: "Home3",
        component: () => import("@/views/test/Home3.vue"),
        meta: {
          title: "菜单3",
          noAuth: true,
        },
      },
    ],
  },
  {
    //能耗管理与分析系统
    path: "/energyAnalysis",
    name: "EnergyAnalysis",
    code:'NHGLYFXXT',
    component: BasicLayout,
    redirect: "/energyAnalysis/configuration",
    children: [
      {
        path: "/energyAnalysis/electricityMeter",
        name: "ElectricityMeter",
        component: () =>
          import("@/views/energyAnalysis/electricityMeter/index.vue"),
        meta: {
          title: "电表抄表",
        },
      },
      {
        path: "/energyAnalysis/waterMeter",
        name: "WaterMeter",
        component: () =>
          import("@/views/energyAnalysis/waterMeter/index.vue"),
        meta: {
          title: "水表抄表",
        },
      },
      {
        path: "/energyAnalysis/gasMeter",
        name: "GasMeter",
        component: () =>
          import("@/views/energyAnalysis/gasMeter/index.vue"),
        meta: {
          title: "气表抄表",
        },
      },
      {
        path: "/energyAnalysis/configuration",
        name: "Configuration",
        component: () =>
          import("@/views/energyAnalysis/configuration/Configuration.vue"),
        meta: {
          title: "配置管理",
          noTab: true,
        },
      },
      {
        path: "/energyAnalysis/electricityPlan",
        name: "ElectricityPlan",
        component: () =>
          import("@/views/energyAnalysis/electricityPlan/index.vue"),
        meta: {
          title: "用电计划",
          noTab: true,
        },
      },
      {
        path: "/energyAnalysis/waterPlan",
        name: "WaterPlan",
        component: () =>
          import("@/views/energyAnalysis/waterPlan/index.vue"),
        meta: {
          title: "用水计划",
          noTab: true,
        },
      },
      {
        path: "/energyAnalysis/gasPlan",
        name: "GasPlan",
        component: () =>
          import("@/views/energyAnalysis/gasPlan/index.vue"),
        meta: {
          title: "用气计划",
          noTab: true,
        },
      },
    ],
  },
  {
    //碳管理模块
    path: "/carbon",
    name: "Carbon",
    code:'TGLPT',
    component: BasicLayout,
    redirect: "/carbon/information",
    children: [
      {
        path: "/carbon/menu1",
        redirect: "/carbon/information/manager",
        children:[
          {
            path: "/carbon/information/manager",
            name: "CarbonInformationManager",
            component: () =>
              import("@/views/carbon/information/manager.vue"),
            meta: {
              title: "碳资讯维护",
            },
          },
          {
            path: "/carbon/information",
            name: "CarbonInformation",
            component: () =>
              import("@/views/carbon/information/information.vue"),
            meta: {
              title: "碳资讯查看",
            },
          },
          
          {
            path: "/carbon/information/content/:id",
            name: "CarbonInformationContent",
            component: () =>
              import("@/views/carbon/information/content.vue"),
            meta: {
              title: "碳资讯-详情",
              //noAuth: true,
              activeMenu: ['/carbon/information']
            },
          },
          
          {
            path: "/carbon/information/manager/edit/:id",
            name: "CarbonInformationContentEdit",
            component: () =>
              import("@/views/carbon/information/detail.vue"),
            meta: {
              title: "碳资讯-维护-编辑",
              //noAuth: true,
              activeMenu: ['/carbon/information/manager']
            },
          },
        ]
      },
     
      {
        path: "/carbon/menu2",
        redirect: "/carbon/subitem",
        children:[
          {
            path: "/carbon/subitem",
            name: "CarbonSubitem",
            component: () =>
              import("@/views/carbon/subitem/index.vue"),
            meta: {
              title: "碳类别",
            },
          },
          {
            path: "/carbon/factor",
            name: "CarbonFactor",
            component: () =>
              import("@/views/carbon/factor/index.vue"),
            meta: {
              title: "碳因子库",
            },
          },
          {
            path: "/carbon/activity",
            name: "CarbonActivity",
            component: () =>
              import("@/views/carbon/activity/index.vue"),
            meta: {
              title: "活动设施",
            },
          },
          {
            path: "/carbon/source",
            name: "CarbonSource",
            component: () =>
              import("@/views/carbon/source/index.vue"),
            meta: {
              title: "排放源",
            },
          },
        ]
      }, 
      {
        path: "/carbon/inventory",
        name: "CarbonInventory",
        component: () =>
          import("@/views/carbon/inventory/index.vue"),
        meta: {
          title: "盘查主体管理",
        },
      },
      {
        path: "/carbon/report",
        name: "CarbonReport",
        component: () =>
          import("@/views/carbon/report/index.vue"),
        meta: {
          title: "第三方报告",
        },
      },
      {
        path: "/carbon/targetManage",
        name: "CarbonTargetManage",
        component: () =>
          import("@/views/carbon/targetManage/index.vue"),
        meta: {
          title: "碳目标管理",
        },
      },
    ]
  }
];
const routes = [
  ...mainRouter,
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/login/Login.vue"),
  },
  {
    path: "/template/intelligentControl",
    name: "noAuth-IntelligentControl",
    component: () =>
      import(
        "@/views/centerMonitor/projectMonitor/template/intelligentControl.vue"
      ),
  },
  {
    path: "/centerMonitor/largeScreen/:pathMatch(.*)*",
    name: "largeScreen",
    component: () =>
      import(
        "@/views/centerMonitor/largeScreen/index.vue"
      ),
  },
  {
    path: "/404",
    name: "NotFound",
    component: BasicLayout,
    redirect: "/notFound",
    children: [
      {
        path: "/notFound",
        component: () => import("@/views/notFound/404.vue"),
        meta: {
          title: "404",
        },
      },
    ],
  }
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

export default router;
