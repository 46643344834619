<template>
  <a-upload
    class="upload-button-uploader"
    :disabled="disabled"
    :show-upload-list="showList"
    :before-upload="beforeUpload"
    :customRequest="customRequest"
    :file-list="fileList"
    @remove="handleRemove"
  >
    <a-button :loading="uploadLoading || loading" :type="buttonType">
      <component :is="$antIcons.UploadOutlined" v-if="iconVisible" />
      {{ label }}
    </a-button>
    <template #itemRender="{ file, actions }">
      <a-space>
        <span :style="file.status === 'error' ? 'color: red' : ''">{{ file.name }}</span>
        <a href="javascript:;" @click="actions.remove">删除</a>
      </a-space>
    </template>
  </a-upload>
</template>

<script setup>
import { defineEmits, defineProps, ref } from "vue";
import { apiUploadFile } from "@/api/common";
import { message } from "ant-design-vue";
const props = defineProps({
  label: {
    type: String,
    default: "上传",
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  showList:{
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  customFunc: {
    type: Function,
    default: null,
  },
  iconVisible: {
    type: Boolean,
    default: true,
  },
  buttonType: {
    type: String,
    default: "primary",
  },
  fileType: {
    type: String,
    default: "image",
  },
  fileList:{
    type: Object,
    default(){
      return [{
        uid: '-1',
        name: 'xxx.png',
        status: 'done',
        url: 'http://www.baidu.com/xxx.png',
      }]
    }
  }
});
const emit = defineEmits(["uploadSuccess",'remove']);
const uploadLoading = ref(false);
const customRequest = async (file) => {
  let formData = new FormData();
  formData.append("file", file.file);
  uploadLoading.value = true;
  try {
    let res = {};
    if (props.customFunc) {
      res = await props.customFunc(formData);
    } else {
      res = await apiUploadFile(formData, "FILE");
    }
    uploadLoading.value = false;
    message.success("上传成功");
    emit("uploadSuccess", res);
  } catch (error) {
    uploadLoading.value = false;
    console.log(error);
  }
};
const handleRemove = (arg)=>{
  emit("remove", arg);
}
const beforeUpload = (file) => {
  if (props.fileType === "image") {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("仅支持png、jpg格式文件!");
    }
    const isLt2M = file.size / 1024 / 1024 < 10;
    if (!isLt2M) {
      message.error("图片大小不能超过10MB!");
    }
    return isJpgOrPng && isLt2M;
  }
  return true;
};
</script>

<style lang="less" scoped></style>
